<template>
	<div class="members">
		<v-menu
			:nudge-width="2300"
			max-width="300"
			left
			v-model="memebresCard"
			offset-y
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-checkbox-marked-circle-outline</v-icon>Checklist</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Add checklist</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="memebresCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2">
				<v-form>
					<div>
						<label class="input-label">Title</label>
						<TextInput hide-details outlined v-model="title" placeholder="Title" class="mt-0">
						</TextInput>
					</div>
					<div class="my-2 checklist_card">
						<label class="input-label">Copy items from…</label>
						<v-select
							hide-details
							outlined
							v-model="copy_items"
							placeholder="Copy items from…"
							:items="copyitemsList"
						></v-select>
					</div>
					<div>
						<v-btn color="blue darken-2" class="white--text">Add</v-btn>
					</div>
				</v-form>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	data() {
		return {
			memebresCard: false,
			title: "",
			copy_items: "",
			copyitemsList: ["(none)", "Checklist1", "Checklist2"],
		};
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
</style>
