<template>
	<div class="status_font_family">
		<v-menu left offset-y>
			<!-- :style="`border: 1px solid ${getStatusColor(currentStatus.title)}`" -->
			<template v-slot:activator="{ on, attrs }">
				<v-chip
					class="me-4"
					v-bind="attrs"
					v-on="on"
					:color="currentStatus.color"
					style="text-transform: capitalize !important"
					link
					text-color="white"
					label
					small
				>
					{{ currentStatus.title }}
					<v-icon class="">mdi-chevron-down</v-icon>
				</v-chip>
			</template>
			<v-list subheader>
				<v-subheader inset class="fw-500 text-h6"> Status </v-subheader>
				<v-list-item
					v-for="(row, index) in statusList"
					:key="index"
					:value="row.value"
					@click="setStatus(row)"
				>
					<v-list-item-avatar size="20">
						<v-icon :class="row.color"></v-icon>
					</v-list-item-avatar>
					<v-list-item-title style="text-transform: capitalize !important" class="ms-2 fw-500">{{
						row.title
					}}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
export default {
	name: "DueDateStatus",

	data() {
		return {
			statusList: [
				{ title: "Overdue", value: "overdue", color: "red" },
				{ title: "Completed", value: "completed", color: "success" },
				{ title: "due soon", value: "soon", color: "orange" },
			],
			currentStatus: { title: "Overdue", value: "overdue", color: "red" },
		};
	},
	methods: {
		// getStatusColor(type) {
		// 	if (type == "Overdue") {
		// 		return "#ea3829";
		// 	} else if (type == "Completed") {
		// 		return "#28c76f";
		// 	} else if (type == "due soon") {
		// 		return "#008f5d";
		// 	}
		// },
		setStatus(row) {
			this.currentStatus = row;
			//console.log(row);
		},
	},
};
</script>
<style scoped>
.status_font_family {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px;
}
</style>
