<template>
	<v-menu
		:nudge-width="300"
		max-width="300"
		v-model="actionListMenu"
		left
		offset-y
		:close-on-content-click="false"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn small icon v-bind="attrs" v-on="on">
				<v-icon> mdi-dots-horizontal</v-icon>
			</v-btn>
		</template>
		<v-list subheader>
			<div class="d-flex align-center">
				<v-btn @click="backToList()" depressed small class="ms-2" icon
					><v-icon>mdi-chevron-left</v-icon></v-btn
				>
				<v-spacer></v-spacer>
				<template v-if="active_list == 'event2'">
					<v-subheader class="h5 mx-auto d-flex align-cener justify-content-between"
						>Copy List</v-subheader
					>
				</template>
				<template v-if="active_list == 'event3'">
					<v-subheader class="h5 mx-auto d-flex align-cener justify-content-between"
						>Move List</v-subheader
					>
				</template>
				<template v-if="active_list == 'event5'">
					<v-subheader class="h5 mx-auto d-flex align-cener justify-content-between"
						>Sort List</v-subheader
					>
				</template>
				<template
					v-if="
						active_list == 'menu' ||
						active_list == 'event4' ||
						active_list == 'event1' ||
						active_list == 'event6'
					"
				>
					<v-subheader class="h5 mx-auto d-flex align-cener justify-content-between"
						>List actions</v-subheader
					>
				</template>
				<v-spacer></v-spacer>
				<v-btn @click="actionListMenu = false" depressed small class="me-2" icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</div>
			<template
				v-if="
					active_list == 'menu' ||
					active_list == 'event4' ||
					active_list == 'event1' ||
					active_list == 'event6'
				"
			>
				<v-list-item
					style="height: 28px"
					class="py-1"
					link
					v-for="(item, index) in menuitems"
					:key="index"
				>
					<v-list-item-title
						@click="listactionsEvent(item)"
						style="
							color: #333333 !important;
							font-size: 13px !important;
							font-weight: 400 !important;
							text-transform: capitalize !important;
						"
						>{{ item.title }}
						<v-icon small v-if="item.actionType == 'event4'">{{ watchStatus ? "mdi-check" : "" }}</v-icon>
					</v-list-item-title>
				</v-list-item>
			</template>
			<template v-if="active_list == 'event2'">
				<v-list-item>
					<v-list-item-title>
						<div class="mb-2">
							<label class="input-label">Name</label>
							<div class="">
								<TextAreaInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									v-model="name_field"
								></TextAreaInput>
							</div>
						</div>
						<div class="pb-2">
							<label class="input-label">Keep..</label>
							<template v-for="(row, index) in keepCheckBox">
								<v-checkbox
									:key="index"
									hide-details
									class="mx-0 my-0 input-label"
									v-model="row.checklists"
									:label="row.titleLabel"
								></v-checkbox>
							</template>
						</div>
						<div class="pt-2">
							<label class="input-label">Copy to..</label>
							<v-select
								hide-details
								v-model="designation"
								:items="designationList"
								attach
								chips
								label="Project"
								class="rounded"
								style="background-color: rgb(238, 231, 231)"
							></v-select>
							<div class="d-flex pt-2">
								<v-select
									hide-details
									v-model="list"
									:items="moveList"
									attach
									chips
									label="List"
									class="rounded me-2"
									style="background-color: rgb(238, 231, 231)"
								></v-select>
								<v-select
									hide-details
									v-model="position"
									:items="positionList"
									attach
									chips
									label="Position"
									class="rounded"
									style="background-color: rgb(238, 231, 231)"
								></v-select>
							</div>
						</div>
						<div class="pt-3">
							<v-btn color="blue darken-2" class="white--text">Create list</v-btn>
						</div>
					</v-list-item-title>
				</v-list-item>
			</template>
			<template v-if="active_list == 'event3'">
				<v-list-item>
					<v-list-item-title>
						<div class="mb-2">
							<div class="" style="background-color: #f1f2f4; border-radius: 4px; padding-left: 3px">
								<v-autocomplete
									hide-details
									v-model="board_value"
									:items="board__items"
									label="Board"
								></v-autocomplete>
							</div>
						</div>
						<div class="mb-2">
							<div class="" style="background-color: #f1f2f4; border-radius: 4px; padding-left: 3px">
								<v-autocomplete
									hide-details
									v-model="position_value"
									:items="position__items"
									label="Position"
								></v-autocomplete>
							</div>
						</div>
						<div class="pt-3">
							<v-btn color="blue darken-2" class="white--text">Move</v-btn>
						</div>
					</v-list-item-title>
				</v-list-item>
			</template>
			<template v-if="active_list == 'event5'">
				<v-list-item
					style="height: 28px"
					class="py-2"
					link
					v-for="(row, index) in sortitemsList"
					:key="index"
				>
					<v-list-item-title
						style="
							color: #333333 !important;
							font-size: 13px !important;
							font-weight: 400 !important;
							text-transform: lowercase !important;
						"
						>{{ row.title }}</v-list-item-title
					>
				</v-list-item>
			</template>
			<!-- <v-divider class="mx-2"></v-divider> -->
		</v-list>
	</v-menu>
</template>
<script>
import TextAreaInput from "@/view/components/TextAreaInput";
export default {
	name: "ListActionsMenu",
	data() {
		return {
			pageLoading: false,
			actionListMenu: false,
			name_field: "Done",
			active_list: "menu",
			list_dialogs: {
				add_card: false,
				copy_list: false,
				move_list: false,
				short_list: false,
			},
			menuitems: [
				{
					id: 1,
					actionType: "event1",
					title: "Add card...",
				},
				{
					id: 2,
					actionType: "event2",
					title: "Copy all cards...",
				},
				{
					id: 3,
					actionType: "event3",
					title: "Move list...",
				},
				{
					id: 4,
					actionType: "event4",
					title: "Watch",
				},
				{
					id: 5,
					actionType: "event5",
					title: "Short by...",
				},
				{
					id: 6,
					actionType: "event6",
					title: "Delete",
				},
			],
			board__items: ["John Doe"],
			board_value: "John Doe",
			position_value: 2,
			position__items: [1, 2, 3, 4],
			sortitemsList: [
				{
					id: 1,
					title: "Date created(newest first)",
				},
				{
					id: 2,
					title: "Date created(oldest first)",
				},
				{
					id: 3,
					title: "Date name(alphabetically)",
				},
				{
					id: 4,
					title: "Due date",
				},
			],
			keepCheckBox: [
				{
					id: 1,
					checklists: false,
					titleLabel: "Checklists(1)",
				},
				{
					id: 2,
					checklists: false,
					titleLabel: "Labels(1)",
				},
				{
					id: 3,
					checklists: false,
					titleLabel: "Attachments(1)",
				},
				{
					id: 4,
					checklists: false,
					titleLabel: "Comments(1)",
				},
			],
			designation: "John doe",
			list: "To do",
			position: 1,
			designationList: ["John doe", "Maxwell"],
			positionList: [1, 2, 3, 4],
			moveList: ["To do", "Doing", "done", "buzz"],
		};
	},
	props: {
		parentIndex: {
			type: Number,
		},
		watchStatus: {
			type: Boolean,
		},
		showEdit: {
			type: Boolean,
		},
	},
	methods: {
		backToList() {
			this.active_list = "menu";
		},
		listactionsEvent(item) {
			this.active_list = item.actionType;
			switch (item.actionType) {
				case "event1":
					this.actionListMenu = false;
					this.$emit("open:edit", this.parentIndex, this.showEdit);
					console.log("Event 1 clicked");
					break;
				case "event4":
					this.$emit("update:watch", this.parentIndex, this.watchStatus);
					console.log("Event 4 clicked");
					break;
				case "event6":
					this.actionListMenu = false;
					console.log("Event 6 clicked");
					break;
			}
		},
	},
	components: {
		TextAreaInput,
	},
};
</script>
<style scoped>
.input-label {
	font-weight: 400;
	font-size: 13px;
	display: block;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	text-transform: capitalize;
}
</style>
