<template>
	<v-menu
		:nudge-width="300"
		max-width="300"
		v-model="shareCardMenu"
		offset-y
		left
		:close-on-content-click="false"
		top
		class="due-date-menu"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" depressed width="100%"
				><v-icon left>mdi-share</v-icon>Share</v-btn
			>
		</template>
		<v-toolbar color="blue darken-4" dark>
			<div class="">
				<div class="text-h6 title_card">Share and more…</div>
			</div>
			<v-btn absolute top right class="me-1" @click="shareCardMenu = false" small depressed icon
				><v-icon>mdi-close</v-icon></v-btn
			>
		</v-toolbar>
		<v-list class="px-3 share_list">
			<!-- <v-list-item-group color="">
				<v-list-item class="px-0 share_listing">
					<v-list-item-content>
						<v-list-item-title @click="triggerPrintShortcut">Print..</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item class="px-0 share_listing">
					<v-list-item-content>
						<v-list-item-title>Export JSON</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
			<v-divider></v-divider> -->
			<div class="mb-2">
				<label class="input-label">Link to this card<v-icon small>mdi-account-multiple</v-icon></label>
				<TextInput class="mt-0" v-model="link_card" hide-details outlined></TextInput>
			</div>
			<div class="mb-2">
				<label class="input-label">Email for this card</label>
				<TextInput class="mt-0 mb-1" v-model="email_card" hide-details outlined></TextInput>
				<span style="font-size: 12px"
					>Emails sent to this address will appear as a comment by you on the card</span
				>
			</div>
			<v-divider></v-divider>
			<div class="d-flex align-center">
				<span style="font-size: 12px">Card #2 Added Sep 25 at 9:46 AM</span
				><v-btn class="px-0 delete_btn" color="blue darken-4" plain>Delete</v-btn>
			</div>
		</v-list>
	</v-menu>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "ShareCard",
	data() {
		return {
			shareCardMenu: false,
			link_card: null,
			email_card: null,
		};
	},
	methods: {
		triggerPrintShortcut() {
			console.log(print);
			window.print();
		},
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
.due-date-menu .v-application .v-list.v-sheet .v-list-item .v-list-item__title {
	font-size: 13px !important;
	font-weight: 500 !important;
	text-transform: capitalize !important;
	color: #262626 !important;
}
.share_list .v-list-item__content .v-list-item__title {
	text-transform: capitalize !important;
	color: #000000de !important;
	padding: 2px 4px !important;
	font-weight: 400 !important;
}

.delete_btn:hover {
	text-decoration: underline;
}
</style>
