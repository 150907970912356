<template>
	<div class="members">
		<v-menu
			:nudge-width="300"
			max-width="300"
			v-model="memebresCard"
			left
			offset-y
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-if="createLabel"
					v-bind="attrs"
					v-on="on"
					style="min-width: 40px !important"
					depressed
					class="mr-1"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
				<v-btn v-else v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-label-outline</v-icon>Labels</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<template v-if="editLabels">
					<v-btn class="me-1" @click="backToMain()" small depressed icon
						><v-icon>mdi-chevron-left</v-icon></v-btn
					>
					<v-spacer></v-spacer>
				</template>
				<div class="text-h6 text-center title_card">Labels</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="memebresCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<template v-if="editLabels">
				<v-list class="py-5 px-5" color="grey lighten-4">
					<v-card min-height="30" :color="selected_color"></v-card>
				</v-list>
				<v-list class="px-2 pb-0">
					<v-form>
						<div>
							<label class="input-label">Title</label>
							<TextInput
								hide-details
								class="mt-0 mb-3"
								outlined
								v-model="title_labels"
								placeholder="Search labels.."
							>
							</TextInput>
						</div>
					</v-form>
					<span class="grey--text">Select a color</span>
					<div class="w-100 d-block">
						<v-color-picker
							v-model="selected_color"
							hide-canvas
							hide-inputs
							hide-mode-switch
							hide-sliders
							show-swatches
							swatches-max-height="200"
						></v-color-picker>
					</div>
					<v-btn depressed class="w-100 mt-3"><v-icon>mdi-close</v-icon>Remove order</v-btn>
				</v-list>
				<v-list class="px-2 card-member">
					<v-divider></v-divider>
					<div class="d-flex justify-space-between">
						<v-btn color="blue darken-4" class="white--text" depressed>Save</v-btn>
						<v-btn class="white--text" color="red" depressed>Delete</v-btn>
					</div>
				</v-list>
			</template>
			<template v-else>
				<v-list class="px-2">
					<v-form>
						<div>
							<TextInput hide-details outlined v-model="seach_labels" placeholder="Search labels..">
							</TextInput>
						</div>
					</v-form>
				</v-list>
				<v-list class="px-3">
					<span class="grey--text">Labels</span>
					<!-- <v-list-item-group multiple> -->
					<template v-for="(item, index) in boardLabels">
						<v-list-item
							@click="onClickAddLabel(index)"
							class="px-0"
							:key="item.title"
							style="min-height: 34px !important; height: 34px"
						>
							<template v-slot:default="{ active }">
								<v-list-item-action class="ms-0 me-2 my-0">
									<v-checkbox hide-details :input-value="active" v-model="item.checkLabels">
										<template #label>
											<v-list-item-title>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-bind="attrs"
															v-on="on"
															depressed
															class="w-100"
															:color="item.color"
															style="justify-content: flex-start; width: 200px !important"
															>{{ item.title }}</v-btn
														>
													</template>
													<span>On Page</span>
												</v-tooltip>
												<v-btn
													class="ms-2"
													@click.stop.prevent="editLabels = true"
													style="min-width: 40px !important"
													depressed
												>
													<v-icon size="16">mdi-pencil-outline</v-icon>
												</v-btn>
											</v-list-item-title>
										</template>
									</v-checkbox>
								</v-list-item-action>
							</template>
						</v-list-item>
						<v-divider v-if="false" :key="index"></v-divider>
					</template>
					<!-- </v-list-item-group> -->
				</v-list>
				<v-list class="px-2 card-member">
					<div class="text-center">
						<v-btn @click="editLabels = true" class="w-100" depressed>Create a new label</v-btn>
						<v-divider></v-divider>
						<v-btn class="w-100" depressed>Enable colorblind friendly mode</v-btn>
					</div>
				</v-list>
			</template>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	props: ["createLabel"],
	data() {
		return {
			active_list: "menu",
			memebresCard: false,
			editLabels: false,
			seach_labels: "",
			copy_items: "",
			title_labels: "On Page",
			selected_color: "#FF00FF",
			copyitemsList: ["None", "Project Planing", "Project Planing2", "Project Planing3"],
			boardLabels: [
				{
					id: 1,
					color: "green",
					title: "On page",
					checkLabels: true,
				},
				{
					id: 2,
					color: "orange",
					title: "Off page",
					checkLabels: false,
				},
				{
					id: 3,
					color: "yellow",
					title: "",
					checkLabels: false,
				},
			],
		};
	},
	methods: {
		onClickAddLabel(index) {
			// Emit an event to notify the parent component of the color change
			this.$emit("add-label", this.boardLabels[index]);
		},
		backToMain() {
			this.editLabels = false;
		},
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 13px !important;
}
.members-check .v-input__control {
	width: auto !important;
}
</style>
