<template>
	<v-menu v-model="duedateMenu" offset-y :close-on-content-click="false" top class="due-date-menu">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" depressed width="100%"
				><v-icon left>mdi-clock-outline</v-icon>Dates</v-btn
			>
		</template>
		<v-toolbar color="blue darken-4" dark>
			<div class="">
				<div class="text-h6 title_card">Dates</div>
			</div>
			<v-btn absolute top right class="me-1" @click="duedateMenu = false" small depressed icon
				><v-icon>mdi-close</v-icon></v-btn
			>
		</v-toolbar>
		<v-list class="px-3">
			<v-form>
				<div class="mb-2">
					<v-date-picker range no-title v-model="due_date" show-adjacent-months></v-date-picker>
				</div>
				<div class="mb-2">
					<label class="input-label">Start date</label>
					<div class="d-flex align-center">
						<v-checkbox
							class="mb-0 mt-2 my-0"
							@click="disabled = (disabled + 1) % 2"
							hide-details
							v-model="startDateCheck"
						></v-checkbox>
						<TextInput
							:disabled="!disabled == 1"
							style="max-width: 100px"
							v-model="dueDateList.start_date_field"
							hide-details
							outlined
						></TextInput>
					</div>
				</div>
				<div class="mb-2">
					<label class="input-label">Due date</label>
					<div class="d-flex align-center">
						<v-checkbox
							@click="disabled_due = (disabled_due + 1) % 2"
							class="mb-0 mt-2 mb-0"
							hide-details
							v-model="dueDateCheck"
						></v-checkbox>
						<TextInput
							:disabled="!disabled_due == 1"
							style="max-width: 100px; margin-right: 8px"
							v-model="dueDateList.start_date_field"
							hide-details
							placeholder=""
							outlined
						></TextInput>
						<TextInput
							:disabled="!disabled_due == 1"
							style="max-width: 100px"
							v-model="dueDateList.due_time_field"
							hide-details
							outlined
						></TextInput>
					</div>
				</div>
				<div class="mb-2 set-reminder">
					<label class="input-label">Set due date reminder</label>
					<v-select
						hide-details
						outlined
						v-model="dueDateList.due_date_reminder"
						:items="reminderDateList"
						:rules="[(v) => !!v || 'Set due date reminder']"
						required
						class="mb-2"
					></v-select>
					<span
						>Reminders will be sent to all members<br />
						and watchers of this card.</span
					>
				</div>
				<div class="mb-2">
					<v-btn class="white--text d-block w-100 mb-2" depressed color="blue darken-4">Save</v-btn>
					<v-btn class="d-block w-100" depressed>Cancel</v-btn>
				</div>
			</v-form>
		</v-list>
	</v-menu>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "DatesCard",
	data() {
		return {
			duedateMenu: false,
			disabled: 0,
			disabled_due: 0,
			startDateCheck: "",
			due_date: "",
			dueDateCheck: "",
			reminderDateList: [
				"None",
				"At time of due date",
				"5 Minutes before",
				"10 Minutes before",
				"15 Minutes before",
				"1 Hour before",
				"2 Hour before",
				"1 Day before",
				"2 Day before",
			],
			dueDateList: {
				due_time_field: "10:30 AM",
				start_date_field: "2019-09-10",
				due_date_reminder: "1 Day before",
			},
		};
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
.due-date-menu .v-application .v-list.v-sheet .v-list-item .v-list-item__title {
	font-size: 13px !important;
	font-weight: 500 !important;
	text-transform: capitalize !important;
	color: #262626 !important;
}
</style>
