<template>
	<div class="members">
		<v-menu
			:nudge-width="300"
			max-width="300"
			v-model="attachCard"
			left
			offset-y
			bottom
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-if="addattachment" v-bind="attrs" v-on="on" depressed>Add</v-btn>
				<v-btn v-else v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-attachment</v-icon>Attachment</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<v-menu
					:nudge-width="300"
					max-width="300"
					v-model="moreInfoMenu"
					bottom
					left
					transition="scale-transition"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" class="ms-1" small depressed icon
							><v-icon small>mdi-information-outline</v-icon></v-btn
						>
					</template>

					<v-list class="px-4">
						<div class="py-2 d-flex align-center">
							<v-spacer></v-spacer>
							<subheader>More Info</subheader>
							<v-spacer></v-spacer>
							<v-btn @click="moreInfoMenu = true" depressed icon small><v-icon>mdi-close</v-icon></v-btn>
						</div>
						<div class="">
							<v-img
								max-height="150"
								max-width="150"
								class="mx-auto"
								src="https://trello.com/assets/0b3466e653946c5b38e3.svg"
							></v-img>
						</div>
						<div class="">
							<p class="pb-2" style="font-size: 13px; color: rgb(95, 92, 92)">
								Attach Lead, Jira, or Confluence links by selecting from recent items or searching directly
								below.
							</p>
							<p style="font-size: 13px; color: rgb(95, 92, 92)">
								Paste links from anywhere including Google Drive, OneDrive, Dropbox, and more.
							</p>
						</div>
						<div class=""></div>
					</v-list>
				</v-menu>
				<v-spacer></v-spacer>
				<div class="text-h6 text-center title_card">Attach</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="attachCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2">
				<p class="mb-0 darkgrey--text"><strong>Attach a file from your computer</strong></p>
				<span style="font-size: 12px; font-weight: 400 !important"
					>You can also drag and drop files to upload them.</span
				>
				<div>
					<v-btn class="w-100" @click="choseFile()" depressed>Choose a file</v-btn>
					<input type="file" hidden ref="chooe_file" />
				</div>
				<v-divider></v-divider>
			</v-list>
			<v-list class="px-2 pt-0">
				<v-form>
					<div class="pb-2">
						<label class="input-label">Search or paste a link</label>
						<TextInput
							clearable
							hide-details
							outlined
							v-model="search_link"
							placeholder="Find recent links or paste a new link"
							class="mt-0"
						>
						</TextInput>
					</div>
					<div class="py-2">
						<label class="input-label">Display text (optional)</label>
						<TextInput
							hide-details
							outlined
							v-model="display_text"
							placeholder="Text to display"
							class="mt-0"
						>
						</TextInput>
					</div>
				</v-form>
			</v-list>
			<v-list class="">
				<span class="px-3">Recently Viewed</span>
				<v-list-item-group color="primary">
					<v-list-item v-for="(item, i) in items" :key="i">
						<v-list-item-icon class="me-0 ms-2">
							<v-icon color="blue darken-4" small v-text="item.icon"></v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title
								style="text-transform: capitalize !important; color: #000 !important; opacity: 0.7"
								v-text="item.text"
							></v-list-item-title>
							<v-list-item-subtitle>
								John doe <v-icon small>mdi-circle-small</v-icon> Viewed 5 days ago
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
				<div class="text-right my-2 px-3">
					<v-btn class="me-2" depressed>cancel</v-btn>
					<v-btn color="blue darken-2" class="white--text">Insert</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	props: ["addattachment"],
	data() {
		return {
			attachCard: false,
			search_link: "",
			display_text: "",
			moreInfoMenu: false,
			copyitemsList: ["None", "Project Planing", "Project Planing2", "Project Planing3"],
			items: [
				{ text: "john doe", icon: "mdi-television" },
				{ text: "maxwell", icon: "mdi-television" },
			],
		};
	},
	methods: {
		choseFile() {
			this.$nextTick(() => {
				this.$refs.chooe_file.click();
				console.log(this.$refs.chooe_file);
			});
		},
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
</style>
