<template>
	<div class="d-flex">
		<v-menu
			class="custom_font_family"
			:nudge-width="300"
			max-width="300"
			v-model="closeMemberMenu"
			offset-y
			:close-on-content-click="false"
			v-for="(row, index) in addMember"
			:key="index"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-avatar class="me-1" v-bind="attrs" v-on="on" color="blue" size="30">
					<span class="text-black text-h6 title_card">{{ row.title[0] }}</span>
				</v-avatar>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<v-btn color="blue" fab dark x-large absolute bottom left>
					<div class="text-h3 text-black title_card">{{ row.title[0] }}</div>
				</v-btn>
				<div class="pt-3" style="max-width: 80px; margin: 0 auto">
					<div class="text-h6 title_card">{{ row.title }}</div>
					<div>john@gmail.com</div>
				</div>
				<v-btn absolute top right class="me-1" @click="closeMemberMenu = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list two-line subheader>
				<v-subheader inset> </v-subheader>

				<v-list-item link>
					<v-list-item-content>
						<v-list-item-title
							style="font-weight: 400 !important; text-transform: capitalize !important"
							class="py-1"
							>Edit profile info</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>

				<v-divider class="mx-2"></v-divider>
				<v-list-item link>
					<v-list-item-content>
						<v-list-item-title
							style="font-weight: 400 !important; text-transform: capitalize !important"
							class="py-1"
							>Remove from card</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>
		<Membercard :createMember="openMemberDialog" @add-member="onClickMember"></Membercard>
	</div>
</template>
<script>
import Membercard from "./Membercard.vue";
export default {
	name: "MembersAddDialog",
	data() {
		return {
			createMember: false,
			openMemberDialog: true,
			closeMemberMenu: false,
			addMember: [
				{
					id: 1,
					title: "John Doe",
					email: "johndoe@12",
					color: "orange",
				},
			],
		};
	},
	methods: {
		onClickMember(row) {
			// Check if addMember already contains an item with the same id
			const existingItem = this.addMember.find((item) => item.id === row.id);
			if (existingItem) {
				// If the item exists, remove it
				this.addMember = this.addMember.filter((item) => item.id !== row.id);
			} else {
				// If the item doesn't exist, add it to addMember
				this.addMember.push({ id: row.id, title: row.title });
			}
		},
	},
	components: {
		Membercard,
	},
};
</script>
<style scoped>
.custom_font_family {
	/* color: #172b4d; */
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400;
	line-height: 20px;
}
</style>
